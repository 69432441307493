import angular from 'angular'
import SearchCtrl from "./SearchCtrl";
import Routing from './../../routing';

const DistributorController = ($scope, $http) => {

    $scope.EMAIL_REGEXP    = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5})$/;

    $scope.distributor = {
        'contact_people': null,
        'email': null,
        'phone': null,
        'type': null,
        'subject': null,
        'msg': null
    };

    $scope.selectedType = {
      'label': 'Tipo'
    }

    $scope.error = {
        'contact_people': {'status': false, 'text': 'Campo obligatorio'},
        'email': {'status': false, 'text': 'Campo obligatorio o formato incorrecto'},
        'phone': {'status': false, 'text': 'Formato incorrecto'},
        'type': {'status': false, 'text': 'Campo obligatorio'},
        'subject': {'status': false, 'text': 'Campo obligatorio'},
        'msg': {'status':false, 'text': 'Campo obligatorio'}
    };

    $scope.messageerror = {
        'error': false,
        'success': false
    };

    $scope.setType = function (type) {
        $scope.selectedType.label = type;
        $scope.distributor.type = type;
    }

    $scope.submitValidation = function (e) {
        var error = false;
        $scope.error.contact_people.status = false;
        $scope.error.email.status = false;
        $scope.error.phone.status = false;
        $scope.error.type.status = false;
        $scope.error.subject.status = false;
        $scope.error.msg.status = false;

        e.preventDefault();
        if ($scope.distributor.contact_people == null || $scope.distributor.contact_people.trim() == '') {
            error = true;
            $scope.error.contact_people.status = true;
        }
        if ($scope.distributor.email == null || $scope.distributor.email.trim() == '' || false == $scope.EMAIL_REGEXP.test($scope.distributor.email)) {
            error = true;
            $scope.error.email.status = true;
        }
        if ($scope.distributor.phone !== null && Number.isInteger($scope.distributor.phone)) {
            error = true;
            $scope.error.phone.status = true;
        }
        if ($scope.distributor.type == null || $scope.distributor.type.trim() == '') {
            error = true;
            $scope.error.type.status = true;
        }
        if ($scope.distributor.subject == null || $scope.distributor.subject.trim() == '') {
            error = true;
            $scope.error.subject.status = true;
        }
        if ($scope.distributor.msg == null || $scope.distributor.msg.trim() == '') {
            error = true;
            $scope.error.msg.status = true;
        }

        if (!error) {
            document.getElementById("form-contact").submit();
        }
    }
};

DistributorController.$inject = ['$scope', '$http'];

export default DistributorController;
