const AnalyticService = () => {
    var dataAnalytic = {
      brand: null,
      unoauto: null,
      type: null,
      device: null
    };

    function initialize(device, type, brand) {
      dataAnalytic = {
        device: device,
        type: type,
        brand: null
      };
      if (undefined != brand) {
        dataAnalytic.brand = brand;
      }
    }
    function addBrand(brand) {
      dataAnalytic.brand = brand;
    }
    function addUnoauto(unoauto) {
      dataAnalytic.unoauto = unoauto;
    }
    function sendDataAnalytic(analyticsEcommerceData, typeAction) {
        let type = 'nuevo';
        if (!dataAnalytic.unoauto) {
          type = 'oferta';
        }

        //Google Analytic 4
        dataLayer.push({ ecommerce: null });
        dataLayer.push(
          {
            event: "purchase",
            ecommerce:
              {
                transaction_id: analyticsEcommerceData.ecommerce_id,
                affiliation: dataAnalytic.device + '-' + analyticsEcommerceData.scope,
                currency: "EUR",
                items: [
                  {
                    item_name: typeAction,
                    item_id: analyticsEcommerceData.advertiser_id,
                    price: analyticsEcommerceData.price,
                    item_brand: analyticsEcommerceData.brand + '|' + analyticsEcommerceData.family,
                    item_category: type,
                    item_category2: analyticsEcommerceData.type_advertiser,
                    item_category3: analyticsEcommerceData.province,
                    item_category4: analyticsEcommerceData.fuel,
                    item_category5: dataAnalytic.device + '-' + analyticsEcommerceData.scope,
                    quantity: "1"
                  }
                ]
              }
          }
        );
    }
    return {
      initialize : initialize,
      addBrand: addBrand,
      sendDataAnalytic : sendDataAnalytic,
      addUnoauto: addUnoauto
    };
}

export default AnalyticService;
